<template>
  <div id="editVisit" class="item popUpScrollList">
    <image-list ref="cloudinaryUpload" v-model="visit.pictures"
                :edit-mode="editMode"
                :image-view-mode="2"
                :cover-image="visit.firstPictureName"
                :customer-id="visit.customerId"
                @setCoverImage="setCoverImage"
                @uploadingCount="uploadingCountChanged"
    />

    <div style="display: none">
      <p v-if="visit.campaigns && visit.campaigns.length > 0">
        <label for="categoryId">Category ID:</label>
        <input id="categoryId" name="categoryId" v-model="visit.campaigns[0].id"/>
      </p>
    </div>

    <div class="element materialShadow add_entry">
      <reaction-buttons v-model="visit.reactionType"/>

      <div class="lsc-clear"></div>

      <dl class="add_entry_list">
        <dt v-if="visit.reactionType==='idea'" class="anim_fadeIn">
          <h2 class="tp_headline">{{ x('title') }}</h2>
        </dt>
        <dd v-if="visit.reactionType==='idea'" class="anim_fadeIn">
          <input title="title" class="title" v-model="visit.title" :placeholder="x('writeATitle')">
        </dd>

        <customer-select v-show="visit.reactionType !== 'idea'" :customer="visit.customer[0]"
                         :edit-mode="editMode" @updated="updateCustomer"/>

        <dt v-show="userIsInternal && visit.reactionType !== 'idea'">
          <a @click="openCampaignList()" class="campaign_select list_select right"><i
            class="fa fa-chevron-right"/></a>
          <h2 class='tp_headline'>{{ x('type') }}:</h2>
        </dt>

        <dd class="campaign" v-show="userIsInternal && visit.reactionType !== 'idea'">
          <span class="pre_option">
            <span v-if="isLoadingCampaigns" class="loading">
              <i class="fa fa-cog fa-spin"/> {{ x('retrievingDataDots') }}
            </span>
            <a v-for="campaign in quickCampaigns" :key="campaign.id"
               :class="[
                 'shortcut',
                 'ThemeTakePictureBubble',
                 { 'disabled': !hasCampaign(campaign) }
               ]"
               @click="selectQuickCampaign(campaign)">{{ campaign.name }}</a>
          </span>
        </dd>

        <dt v-show="userIsInternal && visit.reactionType !== 'idea'">
          <h2 class='tp_headline'>{{ x('product') }}:</h2>
        </dt>

        <dd class="product" v-show="userIsInternal && visit.reactionType !== 'idea'">
          <span v-for="product in visit.products" class='shortcut ThemeTakePictureBubble'
                :key="product.id">{{ product.name }}<a @click="removeProduct(product.id)"><i
            class='fa fa-close'/></a></span>
          <a @click="openProductList" class="shortcut ThemeTakePictureBubble disabled"><i
            class='fa fa-plus'/>&nbsp;{{
            x('addProduct') }}</a>
        </dd>

        <dd class="description">
          <textarea title="comments" :placeholder="x('commentDots')" name="comment" ref="comments"
                    v-model="visit.comment" maxlength="2000" @focus.passive="gotFocus"/>
        </dd>
      </dl>
    </div>

    <div class="element materialShadow add_entry">
      <div class="missingData" v-show="errorMessage !== ''">
        <i class='fa fa-exclamation-circle'/><br>{{ errorMessage }}
      </div>
      <!-- NOTE these are only used for integrating with the list,
      and not used by the form any more. -->
      <input type="hidden" name="customerId" v-model="visit.customerId">
      <input type="hidden" name="campaignId" v-model="visit.campaignId">
      <input type="hidden" name="productId"
             :value="visit.products ? visit.products.map(v=>v.id).join(',') : ''">
      <!-- NOTE end -->

      <button class="lsc-button lsc-rounded-10 red"
              @click.once="cancel"><i class="fa fa-close"/>{{
        config.translation['cancel'] }}
      </button>
      <button :disabled="isUploading || isSubmitting" type="submit"
              class="lsc-button lsc-rounded-10 green right width100" id="entry_submit" @click="submit">
        <icon name="fa-upload"/>
        <span v-if="isUploading || isSubmitting" class="button-spinner">
          <icon name="fa-cog fa-spin" />
        </span>
        <template v-else>
          {{ x('save') }}
        </template>
      </button>
    </div>

    <div class="add_entry visit_confirm" :class="{ open: isShowingSuccessUI }">
      <div class="response_container">
        <i class='fa fa-check-circle'></i><br>
        {{ responseText }}
      </div>
    </div>
    <div class="hotfix-air"></div>
  </div>
</template>

<script>
  import { defineAsyncComponent } from 'vue';
  import moment from 'moment';
  import { cloneDeep, findIndex } from 'lodash';
  import { mapState } from 'vuex';
  import { httpGet, httpPut, httpPost } from '@/classes/httpHelper';
  import { createNewVisit } from '@/classes/visit-helper';
  import ComponentEventNames from '@/enums/component-event-names';
  import Views from '../../enums/views';
  import ImageList from '../ImageUpload/ImageList';
  import ReactionButtons from './ReactionButtons';
  import CustomerSelect from './CustomerSelect';
  import translate from '../Mixins/Translate';
  import validate from '../../bl/validate-visit';
  import DataTypes from '../../enums/data-types';

  moment.locale('da');

  function addMissingVisitProperties(visit) {
    const result = visit;
    if (visit.products === undefined) {
      result.products = [];
    }
    if (visit.campaigns === undefined) {
      result.campaigns = [];
    }
    return result;
  }

  export default {
    name: 'edit-visit',
    props: {
      loadedVisit: Object,
    },
    emits: [
      ComponentEventNames.didLoad,
      ComponentEventNames.close,
    ],
    components: {
      'image-list': ImageList,
      'reaction-buttons': ReactionButtons,
      'customer-select': CustomerSelect,
    },
    mixins: [translate],
    data() {
      const editMode = this.loadedVisit !== undefined;

      const user = this.$store.state.user;
      const preselectedCustomer = this.$store.state.VisitPlanStore.preselectedCustomer;
      let visit = this.loadedVisit
        ? { ...this.loadedVisit }
        : createNewVisit(user.id, user.userRole, 'like', preselectedCustomer);
      if (editMode) {
        visit = addMissingVisitProperties(visit);
      }

      return {
        availableReactions: [],
        buttonCountClass: 'Options_1',
        userIsInternal: user.userRole.toLowerCase() === 'internal',
        editMode,
        errorMessage: '',
        visit,
        quickCampaigns: [],
        isLoadingCampaigns: true,
        allCampaigns: [],
        isSubmitting: false,
        isUploading: false,
        isShowingSuccessUI: false,
      };
    },
    computed: {
      customer() {
        return this.visit.customer[0] || null;
      },
      ...mapState(['config', 'user']),
      ...mapState('LocationStore', ['location']),
    },
    watch: {
      location: {
        handler(newPosition) {
          if (!this.editMode) {
            this.visit.latitude = newPosition.latitude;
            this.visit.longitude = newPosition.longitude;
          }
        },
        deep: true,
      },
      'visit.reactionType': function fn() {
        this.$store.commit('setCurrentReactionType', this.visit.reactionType);
      },
    },
    async created() {
      if (this.visit) {
        // Ensure that we cannot affect anything outside this component.
        this.visit = cloneDeep(this.visit);
      }

      await this.loadCampaigns();
    },

    mounted() {
      this.$emit(ComponentEventNames.didLoad);
      this.$store.commit('setCurrentReactionType', this.visit.reactionType);
    },

    beforeUnmount() {
      this.$store.commit('setCurrentReactionType', null);
    },

    methods: {
      async gotFocus() {
        if (!this.user.backendDataAccess) return;
        const el = this.$refs.comments;
        const details = {
          time: Date.now(),
          commentFieldDisabled: el.disabled,
          tpType: 'take picture',
          windowSize: `Width: ${window.innerWidth}, Height: ${window.innerHeight}`,
        };
        await httpPost('track', { details });
        console.log('tracked stuff on focus');
      },

      addProduct(id, name) {
        this.visit.products.push({ id, name });
      },

      removeProduct(id) {
        const index = findIndex(this.visit.products, (product) => product.id === id);
        this.visit.products.splice(index, 1);
      },

      async loadCampaigns() {
        let date;
        if (this.editMode) {
          date = moment.utc(this.visit.visitTime).format('DD-MM-YYYY');
        } else {
          date = moment.utc().format('DD-MM-YYYY');
        }
        const campaigns = await httpGet(`campaigns?date=${date}`);
        this.quickCampaigns = campaigns.filter((campaign) => campaign.quickSelect);
        this.allCampaigns = campaigns;
        this.isLoadingCampaigns = false;
        if (!this.editMode) {
          if (this.quickCampaigns.length > 0) this.visit.campaigns.push(this.quickCampaigns[0]);
        }
      },

      hasCampaign(campaign) {
        if (!this.visit.campaigns) return false;
        return this.visit.campaigns.some((c) => c.id === campaign.id);
      },

      selectQuickCampaign(campaign) {
        this.visit.campaigns = [campaign];
      },

      setCampaign(id) {
        const campaign = this.getFullCampaignObject(id);
        this.quickCampaigns = [campaign];
        this.visit.campaigns = [campaign];
      },

      getFullCampaignObject(id) {
        return this.allCampaigns.find((c) => c.id === id);
      },

      updateCustomer(newCustomer) {
        this.visit.customerId = newCustomer.id;
        if (this.visit.customer.length === 0) {
          this.visit.customer.push(newCustomer);
        } else {
          // Ensures vue reactivity
          this.visit.customer.splice(0, 1, newCustomer);
        }
      },

      async openProductList() {
        const SelectorList = defineAsyncComponent(() => import('../SelectorList/SelectorList'));
        this.$store.commit('pushPopup', {
          component: SelectorList,
          direction: 'left',
          title: this.x('chooseProducts'),
          params: {
            type: DataTypes.products,
            selectMultiple: true,
            callback: this.productsSelected,
            initialSelectedItems: this.visit.products,
          },
        });
      },

      productsSelected(products) {
        this.visit.products = products;
      },

      async openCampaignList() {
        const SelectorList = defineAsyncComponent(() => import('../SelectorList/SelectorList'));
        this.$store.commit('pushPopup', {
          component: SelectorList,
          direction: 'left',
          title: this.x('chooseCampaign'),
          params: {
            type: DataTypes.campaigns,
            selected: this.visit.campaigns,
            callback: this.campaignSelected,
            preloadedItems: this.allCampaigns,
          },
        });
      },

      campaignSelected(campaigns) {
        if (campaigns.length > 0) {
          this.setCampaign(campaigns[0].id);
        }
      },

      setCoverImage(imageName) {
        this.visit.firstPictureName = imageName;
      },

      uploadingCountChanged(newCount) {
        this.isUploading = newCount !== 0;
      },

      async cancel() {
        // Roll back image changes. I.e. remove any added images.
        // Removed images are not deleted until commit is called,
        // so we don't need to do anything there.
        if (this.editMode) {
          await this.$refs.cloudinaryUpload.rollbackImageChanges();
        }

        this.$emit('close');
      },

      async submit() {
        if (this.visit.reactionType === 'idea') {
          // Set no customer when an idea is submitted.
          this.visit.customerId = 'NA';
        }
        this.errorMessage = validate(this.visit, this.config.translation);

        if (this.errorMessage === '') {
          // Make sure we don't get double-clicks
          if (this.isSubmitting) return;
          this.isSubmitting = true;
          if (this.editMode) {
            // Update existing visit
            await this.$refs.cloudinaryUpload.commitImageChanges();
            await this.sendUpdateToServer(this.visit);
          } else {
            // Create new visit
            await this.sendCreateToServer(this.visit);
          }
        } else {
          // Remove error message after 3 seconds
          setTimeout(() => { this.errorMessage = ''; }, 3000);
        }
      },

      async sendUpdateToServer(visit) {
        const data = await httpPut(`visits/${visit.id}`, visit);
        if ('error' in data) {
          if (data.error.message in this.config.translation) {
            this.errorMessage = this.config.translation[data.error.message];
          } else {
            this.errorMessage = data.error.message;
          }
          this.isSubmitting = false;
        } else {
          this.visit.pictures = data.pictures;
          this.visit.firstPictureName = data.firstPictureName;
          this.$emit('close');
        }
      },

      async sendCreateToServer(visit) {
        const response = await httpPost('visits', visit);
        if ('error' in response) {
          if (response.error.message in this.config.translation) {
            this.errorMessage = this.config.translation[response.error.message];
          } else {
            this.errorMessage = response.error.message;
          }
          this.isSubmitting = false;
        } else {
          this.showSuccessUI(response);
        }
      },

      showSuccessUI(response) {
        if (response.ok) {
          let errorMessageTimeOut = 2000;

          if (this.config.userRole !== 'internal') {
            errorMessageTimeOut = 4000;
          }
          this.isShowingSuccessUI = true;

          this.$store.commit('setCurrentPage', Views.MyPosts);
          setTimeout(() => {
            this.$store.commit('setTopBarSuffix', '');
            this.$store.commit('popPopup');
            setTimeout(() => {
              console.log('Removing Creation Confirmation');
              this.isShowingSuccessUI = false;
            }, 2000);
          }, errorMessageTimeOut);
        }
      },
    },
  };
</script>

<style scoped>
  .missingData {
    display: block;
  }

  .add_entry_list .campaign_select {
    display: block;
  }

  .title {
    width: 100%;
  }

  button#entry_submit:disabled {
    opacity: 0.5;
  }

  .width100 {
    width: 100px;
  }

  .button-spinner {
    display: inline-block;
    font-size: 20px;
    margin-left: 15px;
    position: absolute;
    margin-top: -3px;
  }
</style>
