export default function validateVisit(visit, lang) {
  if (visit.pictures.length === 0) {
    return lang.validationNoImages;
  }

  if (visit.reactionType === 'idea') {
    if (visit.title === null || visit.title === undefined || visit.title === '') {
      return lang.validationNoTitle;
    }

    if (visit.comment === null || visit.comment === undefined || visit.comment === '') {
      return lang.validationNoComment;
    }
  } else {
    if (visit.customerId === undefined || visit.customerId === null || visit.customerId === '') {
      return lang.validationNoCustomer;
    }

    if (visit.userRole === 'internal') {
      if (visit.campaigns.length === 0) {
        return lang.validationNoCampaign;
      }

      if (visit.products.length === 0) {
        return lang.validationNoProduct;
      }
    }
  }

  return '';
}
