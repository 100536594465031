<template>
  <span class="reactionDisplay" :class="buttonCountClass" v-show="availableReactions.length > 1">
    <a v-for="reaction in availableReactions" @click="updated(reaction)"
       :class="['reaction', reaction, {'selected': editedReactionType === reaction}]"
       :key="reaction">
      <i :class="['fa', config.obj_texts['Icon_' + reaction]]"/>
    </a>
  </span>
</template>

<script>
  import { mapState } from 'vuex';
  import ComponentEventNames from '@/enums/component-event-names';

  export default {
    name: 'reaction-buttons',
    props: ['modelValue'],

    data() {
      return {
        editedReactionType: this.modelValue,
        availableReactions: [],
        buttonCountClass: 'Options_1',
        userIsInternal: this.$store.state.user.userRole.toLowerCase() === 'internal',
      };
    },
    computed: mapState(['config', 'user']),
    watch: {
      modelValue(newValue) {
        this.editedReactionType = newValue;
      },
    },

    created() {
      this.availableReactions = this.user.userRole === 'internal'
        ? this.config.obj_texts.ReactionTypesInternal
        : this.config.obj_texts.ReactionTypesExternal;

      this.buttonCountClass = `Options_${this.availableReactions.length}`;
    },
    methods: {
      updated(newReaction) {
        this.$emit(ComponentEventNames.updateModelValue, newReaction);
      },
    },
  };
</script>
